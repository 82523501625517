import Carousel from 'react-bootstrap/Carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Skeleton } from '@mui/material';

const Slider = ({ Images, isLoading }:any) => {
    const matches = useMediaQuery('(min-width:768px)');
    return (
      <>
        <Carousel keyboard={true}>
                {isLoading?
                    <Skeleton variant="rounded"  height={matches?550:250} />
                    :
                    Images?.map((item:any,i:number)=>(
                      <Carousel.Item key={i} interval={2000}>
                        <img 
                          className={`w-100 img-fluid`}
                          src={item.image} 
                          alt={"Slide "+item.id} 
                          style={{height:matches?550:"100%",width:"100%"}}
                        />
                      </Carousel.Item>
                    ))
                }
        </Carousel>
      </>
    )
}
export default Slider