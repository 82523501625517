import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLoaderStore } from '../zustand_store/loader';
import { HttpService } from '../Api/httpService';

const ContectForm = () => {
  const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state:any) => [state.setIs_Loader_True, state.setIs_Loader_False]);
  const [respmsg, setRespmsg] = useState({ type:'' , msg:'' , open:false });

  return (
    <>
      <div style={{ maxWidth: "100%", marginTop: "150px", marginBottom: "150px" }}>
        <div className="container">
          <div className='row'>
            <div className='text-center'>
              <h1 style={{ fontWeight: "20" }}>Get in Touch</h1>
              <div className="row justify-content-center mt-3">
                <hr style={{ width: '5%', border: '1px solid black' }} />
              </div>
            </div>
          </div>
          <div style={{ maxWidth: "100%", marginTop: "100px" }}></div>

          {respmsg.open && (
            <div className={`alert alert-${respmsg.type === "success" ? "success" : "danger"} rounded-0 d-flex justify-content-between`} role="alert" style={{ marginLeft: '20px', marginRight: '20px' }}>
              <div className="text-center w-100">{respmsg.msg}</div>
              <button type="button" className="btn-close" onClick={() => setRespmsg({ type:'',msg:'',open:false })} />
            </div>
          )}

          <Formik
            initialValues={{ name: "", email: "", subject: "", message: "" }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required('Name is required'),
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              subject: Yup.string().max(255),
              message: Yup.string().required('Message is required'),
            })}
            onSubmit={async (values, action) => {

                SET_LOADER_TRUE();
                HttpService.post(`/api/get-in-touch`, values)
                .then(res => {
                  if (res.status === 200) {
                    console.log(res.data)
                    setRespmsg({ type:'success' , msg: "Message Send Successfully." , open:true });
                    SET_LOADER_FALSE();
                    action.resetForm();
                  }
                })
                .catch(err => {
                  console.log(err?.response);
                  SET_LOADER_FALSE();
                  if (err?.response?.data) {
                    setRespmsg({ type:'danger' , msg: err.response.data.msg , open:true });
                  }
                });

            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
              <form className="contect_form" noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm text-start" style={{ padding: "1px" }}>
                    <input
                      type="text"
                      className="form-control test001"
                      placeholder="Name*"
                      name="name"
                      maxLength={255}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name && (<span className="text-danger">{errors.name}</span>)}
                  </div>

                  <div className="col-sm text-start" style={{ padding: "1px" }}>
                    <input
                      type="email"
                      className="form-control test001"
                      name="email"
                      placeholder="Email*"
                      maxLength={255}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && (<span className="text-danger">{errors.email}</span>)}
                  </div>

                  <div className="col-sm text-start" style={{ padding: "1px" }}>
                    <input
                      type="text"
                      className="form-control test001"
                      name="subject"
                      placeholder="Subject"
                      maxLength={255}
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.subject && errors.subject && (<span className="text-danger">{errors.subject}</span>)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm text-start" style={{ padding: "1px" }}>
                    <textarea
                      className="form-control input_textarea"
                      name="message"
                      rows={8}
                      placeholder="Message*"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.message && errors.message && (<span className="text-danger">{errors.message}</span>)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm text-center" style={{ padding: "1px" }}>
                    <div className="mb-3" style={{ marginTop: "30px" }}>
                      <div className="d-grid">
                        <button type="submit" className="btn btn-dark text-bold rounded-0 contect_form_button">SEND</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
export default ContectForm