import style from '../../styles/Home.module.css';
import { Skeleton, useMediaQuery } from '@mui/material';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import Technologiesslider from '../../Components/Technologies_slider';

const About_Us = () => {

    const { data, isLoading } = useSWR(`/api/page/about-us`, (url) => HttpService.get(url).then((response: any) => response.data.payload));
    const matches = useMediaQuery('(min-width:600px)');

    return (
      <>
        <div className={style.homescroll}>
          <div>
            {isLoading ?
              <Skeleton variant="rounded" width={2000} height={matches ? 550 : 200} />
              :
              <img
                className="img-fluid"
                src={data?.banner}
                alt="about-us"
                style={{ height: matches ? 520 : 200, width: 2000 }}
              />
            }
          </div>
          <div className='container' style={{ marginTop: matches ? "180px" : "100px", marginBottom: matches ? "180px" : "100px" }}>
            <div className='row'>
              <div className='text-center'>
                <div className='row justify-content-center align-items-center flex-column mb-5'>
                  {isLoading ? <Skeleton variant="rounded" width={500} height={60} /> : <p className="display-4" style={{ marginBottom: "10px" }}>{data?.title}</p>}
                  {!isLoading && <hr style={{ width: '5%', border: '1px solid black' }} />}
                </div>
              </div>
            </div>
            <div className="container">
              <span className="row justify-content-md-center">
                <div className="col col-lg-7 text-secondary" style={{ lineHeight: '2' }}>
                  {isLoading ?
                    <Skeleton variant="rounded" width={800} height={150} />
                    :
                    <p>{data?.description}</p>
                  }
                </div>
              </span>
            </div>
          </div>
          <Technologiesslider Images={data?.about_details} isLoading={isLoading} />
        </div>
      </>
    )
}
export default About_Us