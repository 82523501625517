import { Skeleton, useMediaQuery } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import style from '../../styles/Home.module.css';

const Services_Details = () => {
    
    const { service } = useParams();
    const { data, isLoading } = useSWR(`/api/service/${service}`, (url) => HttpService.get(url).then((response) => response.data.payload[0]));
    const mobile = useMediaQuery('(max-width:500px)');

    if(service){
        return(<div className={style.homescroll}>
                <div>
                    {isLoading ?
                        <Skeleton variant="rounded" width={2000} height={mobile ? 550 : 200} />
                        :
                        <div className='d-flex justify-content-center'>
                            <img className="img-fluid" src={data?.image} alt="service" width="100%" height="100%" />
                        </div>
                    }
                </div>
                <div className='container' style={{ marginTop: mobile ? "100px" : "80px", marginBottom: mobile ? "100px" : "80px" }}>
                    <div className='row'>
                        <div className='text-center'>
                            <div className='row justify-content-center align-items-center flex-column'>
                                {isLoading ? 
                                    <Skeleton variant="rounded" width="100%" height={60} /> 
                                    : 
                                    <>
                                        <h1 className={style.title}>{data?.title}</h1>
                                        <hr className="mt-2" style={{ width: '5%', border: '1px solid' }} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container mt-sm-2 mt-md-4">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-sm-12 text-secondary" style={{ lineHeight: '2' }}>
                                {isLoading ? 
                                    <Skeleton variant="rounded" width={800} height={150} /> 
                                    : 
                                    <p style={{wordWrap:'break-word'}}>{data?.long_description}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </div>);
    } else {
        return(<Navigate to='/' replace={true} />);
    }

}
export default Services_Details