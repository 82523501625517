import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div style={{ backgroundColor: 'rgb(51,51,51)' }}>
                <footer className="text-center text-lg-start text-white" >
                    <div className="p-4 pb-0">
                        <section className="">
                            <div className="row mt-5 mb-5">
                                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                        About Us
                                        <hr />
                                    </h6>
                                    <p style={{ color: 'rgb(150,150,150)' }}>
                                        Elance Solution is service based development
                                        company and it is known for great quality of
                                        work and perfect delivery on time.
                                    </p>
                                </div>

                                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                        Our Company
                                        <hr />
                                    </h6>
                                    <p><Link to="/about-us" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}>About Us</Link></p>
                                    <p><Link to="/portfolio" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}>Portfolio</Link></p>
                                    <p><Link to="/contact-us" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}>Contact Us</Link></p>
                                </div>

                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">
                                        Our Services
                                        <hr />
                                    </h6>
                                    <p><Link to="/services/web-designing" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> Web Designing </Link></p>
                                    <p><Link to="/services/web-development" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> Web Development </Link></p>
                                    <p><Link to="/services/app-development" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> App Development </Link></p>
                                    <p><Link to="/services/seo" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> SEO </Link></p>
                                    <p><Link to="/services/api-development" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> Api Development </Link></p>
                                    <p><Link to="/services/support-and-maintenance" style={{ color: 'rgb(150,150,150)', textDecoration: "none" }}> Support {"&"} Maintenance </Link></p>

                                </div>

                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Address <hr /></h6>
                                    <p style={{ color: 'rgb(150,150,150)' }}>443 Hubtown, Adajan Patiya, Surat, Gujarat 395001</p>
                                    Email :<p style={{ color: 'rgb(150,150,150)' }}>info@elancesolution.com</p>
                                    Contact No :<p style={{ color: 'rgb(150,150,150)' }}>+91 8460386995</p>
                                </div>
                            </div>
                        </section>


                    </div>
                    <hr className="my-3" />
                    <section className="p-3 pt-0">
                        <div className="row d-flex align-items-center">
                            <div className="dima-footer text-center">
                                <div className="full-width-footer">
                                    <div className="copyright float-center mt-4 mb-4">
                                        COPYRIGHT © {new Date().getFullYear()} ELANCE SOLUTION
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </footer>

            </div>
        </>
    )
}

export default Footer