import { Skeleton, useMediaQuery } from '@mui/material';
import { Navigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import style from '../../styles/Home.module.css';
import React from 'react';

const Projects_Details = () => {

    const { state } = useLocation();
    const mobile = useMediaQuery('(max-width:500px)');
    const { data, isLoading } = useSWR(`/api/project-details/${state?.id}`, (url) => HttpService.get(url).then((response: any) => response.data.payload[0]));

    if(state?.id){
        return (
            <div className={style.homescroll}>
                <div style={{ backgroundImage: "url('/detail_page_background.jpg')" }}>
                    <div className="container">
                        <div className='row'>
                            <div style={{ maxWidth: "100%", marginTop: "150px" }} />
                                {isLoading ? 
                                    <Skeleton variant="rectangular" width="100%" height={60} /> 
                                    : 
                                    <h1 className={`text-white ${mobile ? "text-center":"text-start"}`}>{data?.title}</h1>
                                }
                            <div style={{ maxWidth: "100%", marginBottom: "150px" }}></div>
                        </div>
                    </div>
                </div>
    
                <div style={{ marginTop: "80px", marginBottom: "80px" }}>
                    <div className="container">
                        <div className="row">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center'>
                                {isLoading ? 
                                    <Skeleton variant="rectangular" width="100%" height={300} /> 
                                    : 
                                    <img src={data?.image} className="img-fluid" alt="img" width={450} height={300} />
                                }
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-secondary d-flex flex-column justify-content-between">
                                {isLoading ? 
                                    <Skeleton variant="rectangular" width="100%" height={100} /> 
                                    : 
                                    <p>{data?.description}</p>
                                }
                                {isLoading ? 
                                    <Skeleton variant="rectangular" width="100%" height={100} /> 
                                    : 
                                    <div>
                                        <hr style={{ width: '100%' }} />
                                        <div className="row">
                                            <div className='col-md-12 d-flex'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48" className='m-2'>
                                                    <path d="M35.27 11.69C34.54 10.67 33.35 10 32 10l-22 .02c-2.21 0-4 1.77-4 3.98v20c0 2.21 1.79 3.98 4 3.98L32 38c1.35 0 2.54-.67 3.27-1.69L44 24l-8.73-12.31z">
                                                    </path>
                                                </svg>
                                                <span className='m-2'>Tags: </span>
                                                <p className='m-2 text-secondary'>
                                                    {isLoading ? <Skeleton variant="rectangular" width="100%" height={50} /> : data?.use_technology}
                                                </p>
                                            </div>
                                        </div>
                                        <hr style={{ width: '100%' }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return(<Navigate to='/' replace={true} />);
    }

}
export default Projects_Details