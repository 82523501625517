import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {

    const {pathname} = useLocation();
    const navigate=useNavigate();
    const HandleNavigate=(e:any,service:any)=>{
        e.preventDefault();
        navigate(`/services/${service}`);
    }
    console.log(pathname.split("/"))
    return(
        <>
            <style>
                {`
                    @import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');
                        body {
                            font-family: "Poppins", Helvetica, Arial, Times, serif;
                        }

                        @media screen and (min-width: 768px) {
                            .navbar>.container-fluid{
                                margin-left: 25px;
                                margin-right: 25px;
                            }
                        }
                        
                `}
            </style>
            <Navbar expand="lg">
                <Container fluid>
                    <NavLink to="/"><img src="/logo.webp" alt='my-logo' height={100} width={200} /></NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <NavLink to="/" className={pathname === "" ? "active" : ""}>Home</NavLink>
                            <NavLink to="/about-us" className={pathname.split("/")[1] === "about-us" ? "active" : ""}>About Us</NavLink>
                            <NavLink to="/portfolio" className={pathname.split("/")[1] === "portfolio" ? "active" : ""}>Portfolio</NavLink>
                            <NavDropdown title="Services" id="services-nav-dropdown" className={pathname.split("/")[1] === "services" ? "active" : ""}>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'web-designing')}> Web Designing </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'web-development')}> Web Development </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'app-development')}> App Development </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'seo')}> SEO </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'api-development')}> Api Development </NavDropdown.Item>
                                <NavDropdown.Item onClick={(e)=>HandleNavigate(e,'support-and-maintenance')}> Support and Maintenance </NavDropdown.Item>
                            </NavDropdown>
                            <NavLink to="/contact-us" className={pathname.split("/")[1] === "contact-us" ? "active" : ""}>Contact Us</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )

}

export default Header