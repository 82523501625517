import Index from './Routes';
import { useLoaderStore } from './zustand_store/loader';
import API_LOADER from './Components/loader';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App(){
    const { pathname } = useLocation();
    const Loading = useLoaderStore((state:any) => state.is_loader);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return(
        <>
            {Loading && <API_LOADER/>}
            <Index />
        </>
    );
}
export default App;