
const Show_Image_Model = (props:any) => {
    const handleClick = (e:any) => {
      if (e.target.classList.contains("dismiss")) {
        props.setClickedImg("");
      }
    };
 
    return (
      <>
        <div className="overlay dismiss" onClick={handleClick} style={{zIndex:100}}>
          <img src={props.clickedImg} alt="bigger pic" />
          <span className="dismiss" onClick={handleClick}>X</span>
        </div>
      </>
    );
  };
  
  export default Show_Image_Model;