import Carousel from 'react-multi-carousel';
// Import react-multi-carousel styles
import 'react-multi-carousel/lib/styles.css';
// Import bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";
import { Skeleton, useMediaQuery } from "@mui/material";

const Technologies_slider = ({ Images, isLoading }:any) => {
    const matches = useMediaQuery('(min-width:600px)');
    
    return (
        <>
            <div style={{ backgroundColor: "#F7F7F7", paddingTop: matches ? 160 : 120, paddingBottom: matches ? 160 : 120 }} >
                <div className="container">
                    <div className='row'>
                        <div className='text-center'>
                            <h1 className="display-6" style={{ fontWeight: "370" }}>Technologies We Use</h1>
                            <div className="row justify-content-center mt-4">
                                <hr style={{ width: '5%', border: '1px solid black' }} />
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading ?
                    <Skeleton variant="rounded" height={400} />
                    :
                    <div className="justify-content-center" style={{ marginTop: matches ? 100 : 50 }}>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={1500}
                            centerMode={false}
                            containerClass="carousel-container"
                            draggable
                            focusOnSelect={false}
                            infinite
                            keyBoardControl
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 3000, min: 1281 },
                                    items: 5,
                                },
                                laptop: {
                                    breakpoint: { max: 1280, min: 1025 },
                                    items: 3,
                                },
                                tablet: {
                                    breakpoint: { max: 1024, min: 481 },
                                    items: 2,
                                },
                                mobile: {
                                    breakpoint: { max: 480, min: 0 },
                                    items: 1,
                                }
                            }}
                            rewind={true}
                            rewindWithAnimation={false}
                            shouldResetAutoplay
                            showDots={false}
                            slidesToSlide={1}
                            removeArrowOnDeviceType={["mobile"]}
                            swipeable
                            transitionDuration={1500}
                            ssr={true}
                        >
                            { 
                                Images?.map((item:any, i:number) => (
                                    <div key={i} className="d-flex justify-content-center align-items-center">
                                        <img className="img-fluid" src={item.image} alt="slider" height="auto" width="auto" />
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                }
            </div>
        </>
    )
}
export default Technologies_slider