import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../Pages/PageNotFound';
import HomePage from '../Pages/Home';
import AboutUs from '../Pages/About_Us';
import Portfolio from '../Pages/Portfolio';
import ContactUs from '../Pages/Contact_Us';
import RootLayout from '../Layout';
import ProjectsDetails from '../Pages/Projects_Details';
import ServicesDetails from '../Pages/Services_Details';

function Index() {

    return (
        <Routes>
            <Route element={<RootLayout />}>
                <Route path='/' element={<HomePage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/projects" element={<ProjectsDetails />} />
                <Route path="/services/:service" element={<ServicesDetails />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
};
export default Index