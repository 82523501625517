import Slider from '../../Components/Slider';
import style from '../../styles/Home.module.css';
import { Skeleton, useMediaQuery } from '@mui/material';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import Portfolio from '../../Components/Portfolio';
import TechnologiesSlider from '../../Components/Technologies_slider';
import ContectForm from '../../Components/ContectForm';

const Home_Page = () => {

    const { data, isLoading } = useSWR(`/api/page/home`, (url) => HttpService.get(url).then((response: any) => response.data.payload));
    const Mobile = useMediaQuery('(min-width:600px)');  

    return (
      <>
        <div className={style.homescroll}>
          <style>
            {`
              @import url('https://fonts.googleapis.com/css?family=Poppins:400,700,900');
                body {
                  font-family: "Poppins", Helvetica, Arial, Times, serif;
                }
                
              @media screen and (min-width: 768px) {
                  .carousel-control-prev-icon{
                      margin-right: 200px;
                  }
                  .carousel-control-next-icon{
                      margin-left: 200px;
                  }
                }
              
            `}
          </style>
          <Slider Images={data?.sliders} isLoading={isLoading} />

          <div className='container' style={{ marginTop: Mobile ? "150px" : "80px", marginBottom: Mobile ? "150px" : "80px" }}>
            <div className='row'>
              <div className='text-center'>
                <div className='row justify-content-center align-items-center flex-column'>
                  {isLoading ? <Skeleton variant="rounded" width={500} height={60} /> : <h1 className={style.title}>{data?.title}</h1>}
                  {!isLoading && <hr style={{ width: '5%', border: '1px solid black' }} />}
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-sm-12 text-secondary" style={{ lineHeight: '2' }}>
                  {isLoading ? <Skeleton variant="rounded" width={800} height={150} /> : <p>{data?.description}</p>}
                </div>
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "#F7F7F7" }}>
            <div className="container" style={{ paddingTop: Mobile ? "50px" : "80px", paddingBottom: Mobile ? "50px" : "80px" }}>
              <div className='row'>
                <div className={style.Our_Services_Space}></div>
                <div className='text-center'>
                  <h1 style={{ fontWeight: "20" }}>Our Services</h1>
                  <div className="row justify-content-center mt-3">
                    <hr style={{ width: '5%', border: '1px solid #000' }} />
                  </div>
                </div>
              </div>
              <div className="container">
                {isLoading ?
                  <Skeleton variant="rounded" height={Mobile ? 600 : 250} />
                  :
                  <div className="row">
                    {data?.services?.map((item: any, i: number) => (
                      <div key={i} className="col-sm-6 col-md-4 text-center" style={{ marginTop: Mobile ? '5rem' : '2rem', marginBottom: Mobile ? '6rem' : '2rem' }}>
                        <div className="mb-4">
                          <i className={item.icon} style={{ fontSize: 35 }} />
                        </div>
                        <h5>{item.title}</h5>
                        <div className="row justify-content-center mt-3">
                          <hr style={{ width: '5%', border: '1px solid black' }} />
                        </div>
                        <span className="text-secondary">{item.description}</span>
                      </div>
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>

          <div style={{ maxWidth: "100%", marginBottom: "100px" }}></div>
          <div className="container">
            <div className='row'>
              <div className='text-center'>
                <h1 style={{ fontWeight: "20" }}>Portfolio</h1>
                <div className="row justify-content-center mt-3">
                  <hr style={{ width: '5%', border: '1px solid black' }} />
                </div>
              </div>
            </div>
          </div>

          <div className={style.Our_Services_Space}></div>

          <Portfolio Category={data?.portfolio_category} isLoading={isLoading} show={false} viewAllBtn={true}/>

          <div style={{ maxWidth: "100%", marginTop: "150px", marginBottom: "150px" }}></div>

          <TechnologiesSlider Images={data?.technologies_slider} isLoading={isLoading} />

          <ContectForm />

        </div>
      </>
    )
}
export default Home_Page