import { useState } from "react";
import style from "../../styles/Home.module.css";
import { Skeleton, useMediaQuery } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLoaderStore } from "../../zustand_store/loader";
import useSWR from 'swr';
import { HttpService } from "../../Api/httpService";

const Contact_Us = () => {

  const { data, isLoading } = useSWR(`/api/page/contact-us`, (url) => HttpService.get(url).then((response: any) => response.data.payload));
  const matches = useMediaQuery("(min-width:600px)");
  const [SET_LOADER_TRUE, SET_LOADER_FALSE] = useLoaderStore((state: any) => [state.setIs_Loader_True,state.setIs_Loader_False]);
  const [respmsg, setRespmsg] = useState({ type: "", msg: "", open: false });

  return (
    <>
      <div className={style.homescroll}>
        <div>
          {isLoading ?
            <Skeleton variant="rectangular" width={2000} height={matches ? 520 : 200} />
            :
            <img
              className="img-fluid"
              src={data?.banner}
              alt="contact-us"
              style={{ height: matches ? 520 : 200, width: 2000 }}
            />
          }
        </div>

        <div className="container" style={{ marginTop: matches ? "140px" : "80px", marginBottom: matches ? "140px" : "80px" }}>
          <div className="row">
            <div className={`col-sm text-center ${matches ? "p-3" : "p-5"}`}>

              {respmsg.open && (
                <div className={`alert alert-${respmsg.type === "success" ? "success" : "danger"} rounded-0 d-flex justify-content-between`} role="alert" style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                  <div className="text-center w-100">{respmsg.msg}</div>
                  <button type="button" className="btn-close" onClick={() => setRespmsg({ type: '', msg: '', open: false })} />
                </div>
              )}

              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  subject: "",
                  message: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().max(255).required("Name is required"),
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  subject: Yup.string().max(255),
                  message: Yup.string().required("Message is required"),
                })}
                onSubmit={async (values, action) => {
                  SET_LOADER_TRUE();
                  HttpService.post(`/api/get-in-touch`, values)
                    .then((res) => {
                      if (res.status === 200) {
                        console.log(res.data);
                        setRespmsg({
                          type: "success",
                          msg: "Message Send Successfully.",
                          open: true,
                        });
                        SET_LOADER_FALSE();
                        action.resetForm();
                      }
                    })
                    .catch((err) => {
                      console.log(err?.response);
                      SET_LOADER_FALSE();
                      if (err?.response?.data) {
                        setRespmsg({
                          type: "danger",
                          msg: err.response.data.msg,
                          open: true,
                        });
                      }
                    });
                }}
              >
                {({ errors,handleBlur,handleChange,handleSubmit,touched,values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="text-start" style={{ padding: "1px" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Name*"}
                          name="name"
                          maxLength={255}
                          style={{
                            borderRadius: "0",
                            border: "none",
                            background: "rgb(244,244,244)",
                            height: "50px",
                          }}
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name && (<span className="text-danger">{errors.name}</span>)}
                      </div>

                      <div className="text-start" style={{ padding: "1px" }}>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email*"
                          maxLength={255}
                          value={values.email}
                          style={{
                            borderRadius: "0",
                            border: "none",
                            background: "rgb(244,244,244)",
                            height: "50px",
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.email && errors.email && (<span className="text-danger">{errors.email}</span>)}
                      </div>

                      <div className="text-start" style={{ padding: "1px" }}>
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Subject"
                          maxLength={255}
                          style={{
                            borderRadius: "0",
                            border: "none",
                            background: "rgb(244,244,244)",
                            height: "50px",
                          }}
                          value={values.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.subject && errors.subject && (<span className="text-danger">{errors.subject}</span>)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="text-start" style={{ padding: "1px" }}>
                        <textarea
                          className="form-control"
                          name="message"
                          rows={8}
                          placeholder="Message*"
                          style={{
                            resize: "none",
                            borderRadius: "0",
                            border: "none",
                            background: "rgb(244,244,244)",
                          }}
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.message && errors.message && (<span className="text-danger">{errors.message}</span>)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="text-center" style={{ padding: "1px" }}>
                        <div className="mb-3" style={{ marginTop: "30px" }}>
                          <div className="d-grid">
                            <button className="btn btn-dark text-bold rounded-0" style={{ height: "52px", backgroundColor: "rgb(51,51,51)" }}>SEND</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

            <div className={`col-sm text-center ${matches ? "p-3" : "p-5"}`}>
              {isLoading ? 
                <Skeleton variant="rectangular" height={425} />
                : 
                <iframe src={data?.location} style={{ border: 0, height: "425px", width: "100%" }} title="current_loaction"/> 
              }
            </div>
          </div>
        </div>

        <div className="bg-light">
          <div className="container">
            <div className="row" style={{ paddingTop: matches ? "140px" : "80px", paddingBottom: matches ? "140px" : "80px" }}>
              {isLoading ? 
                <Skeleton variant="rectangular" height={250} />
                : 
                data['contact-us']?.map((item: any, i: number) => (
                  <div key={i} className="col-sm text-center mt-3 mb-3">
                    <img src={item.image} alt="img" style={{ height: 50, width: 50 }}/>
                    <h5 className="mt-4">{item.title}</h5>
                    <div className="row justify-content-center mt-3">
                      <hr style={{ width: "3%", border: "1px solid black" }} />
                    </div>
                    <div className="text-secondary">{item.descreption}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact_Us;
