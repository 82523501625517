import { useEffect,useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Modal from './Model/Model';
import style from "../styles/Home.module.css";
import 'bootstrap/dist/css/bootstrap.css';
import { Skeleton } from '@mui/material';
import ShowImageModel from './Show_Image_Model';
import { HttpService } from '../Api/httpService';
import { TiArrowRightThick } from "react-icons/ti";

const Portfolio = ({ Category, isLoading, show = true, viewAllBtn = false }:any) => {
    const navigate = useNavigate();
    const [project_id, setProject_ID] = useState<number>(0);
    const [all_projects, setAll_Projects] = useState<object[]>([]);
    const [clickedImg, setClickedImg] = useState("");

    const Portfolio_Details = async () => {
        setAll_Projects([]);
        HttpService.get(`/api/project-category/${project_id}`)
            .then(res => {
                if(res.status===200){  
                    setAll_Projects(project_id > 0 ? res.data.payload.map((item: any) => { return item.project }) : res.data.payload);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        Portfolio_Details();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project_id]);

    return (
        <>
            <div className="container">
                {
                    show ? 
                    <ul className="nav d-flex justify-content-center mb-5">
                    {isLoading ?
                        <Skeleton variant="rectangular" width="100%" height={50} />
                        :
                        <>
                            <li className="nav-item">
                                <p className={`nav-link text-secondary Portfolio_Headers ${project_id === 0 && "bg-primary rounded text-white"}`} onClick={() => setProject_ID(0)}>All</p>
                            </li>
                            {Category?.map((item: any, i: number) => (
                                <li key={i} className="nav-item">
                                    <p className={`nav-link text-secondary Portfolio_Headers ${project_id === item.id && "bg-primary rounded text-white"}`} onClick={() => setProject_ID(item.id)}>{item.name}</p>
                                </li>
                            ))}
                        </>
                    }
                </ul>
                    : null
                }

                {
                    viewAllBtn ?
                    <Link to={"/portfolio"} className='w-100 d-flex justify-content-end align-items-center'>
                        <span  style={{fontSize:16}} className='px-2'>View All</span>
                        <TiArrowRightThick size={20} />
                    </Link>
                    : null
                }
                <div className="row d-flex">
                    {(all_projects.length > 0) ?
                        all_projects.slice(0,viewAllBtn ? 6 : all_projects.length  ).map((item: any, i: number) => (
                            <div key={i} className="col-md-4 col-12 justify-content-center p-4">
                                <div className={style.container}>
                                    <img
                                        className="img-fluid"
                                        src={item.image}
                                        alt="Images"
                                        height="100%"
                                        width="100%"
                                    />
                                    <div className={style.button}>
                                        <button type='button' aria-label="view" onClick={() => setClickedImg(item.image)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48"><path fill="#fff" d="M24 9C14 9 5.46 15.22 2 24c3.46 8.78 12 15 22 15s18.54-6.22 22-15C42.54 15.22 34.01 9 24 9zm0 25c-5.52 0-10-4.48-10-10s4.48-10 10-10 10 4.48 10 10-4.48 10-10 10zm0-16c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z"></path></svg>
                                        </button>
                                    </div>
                                    <div className={style.button1}>
                                        <button type='button' aria-label="details" onClick={() => navigate('/projects',{ state: { id: item.id } })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48"><path fill='#fff' d="M12 20c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm24 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-12 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"></path></svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-start portfolio_content pt-3">
                                    <h4>{item.title}</h4>
                                    <hr style={{ width: '5%', border: '1px solid black', marginBottom: '10px', marginTop: '5px' }} />
                                    <p className='text-secondary m-0 p-0'>{item.use_technology}</p>
                                </div>
                            </div>
                        ))
                        :
                        <Skeleton variant="rectangular" width="100%" height={400} />
                    }

                </div>

                {clickedImg && (
                    <ShowImageModel
                        clickedImg={clickedImg}
                        setClickedImg={setClickedImg}
                    />
                )}
            </div>
        </>
    )
}
export default Portfolio