import style from '../../styles/Home.module.css';
import { Skeleton, useMediaQuery } from '@mui/material';
import useSWR from 'swr';
import { HttpService } from '../../Api/httpService';
import Portfolios from '../../Components/Portfolio';

const Portfolio = () => {

    const { data, isLoading } = useSWR(`/api/page/portfolio`, (url) => HttpService.get(url).then((response: any) => response.data.payload));
    const matches = useMediaQuery('(min-width:600px)');

    return (
      <>
        <div>
          {isLoading ?
            <Skeleton variant="rectangular" height={matches ? 550 : 200} />
            :
            <img
              className="img-fluid"
              src={data?.banner}
              alt="portfolio"
              style={{ height: matches ? 520 : 200, width: 2000 }}
            />
          }
        </div>

        <div style={{ backgroundColor: "#FFF" }}>
          <div className="container" style={{ paddingTop: matches ? 160 : 90, paddingBottom: matches ? 160 : 90 }}>
            <div className='row'>
              <div className='text-center'>
                <h1 style={{ fontWeight: 400, fontSize: 50 }}>Our Work</h1>
                <div className="row justify-content-center mt-3">
                  <hr style={{ width: '5%', border: '1px solid black' }} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className={style.Our_Services_Space}></div>
              <Portfolios Category={data?.portfolio_category} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </>
    )
}
export default Portfolio